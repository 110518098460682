.orm-datepicker__input {
  width: auto;
  height: 20px;
  //border: 1px solid $orm_form_border_color;
  display: inline-block;
  font-size: rem-calc(15px);
  font-weight: 600;
  line-height: 1.333333333;
  position: relative;
  white-space: nowrap;
  cursor: pointer;
  i {
    position: absolute;
    margin-left: rem-calc(8px);
    top: rem-calc(2px);
  }

  &.active {
    background-color: $orm_form_bg_inactive_color;
  }

  &.disable {
    pointer-events: none;
    opacity: 0.5;
  }
}

.orm {
  &-dateselect {
    height: rem-calc(48px);

    margin-bottom: rem-calc(16px);
    border: 1px solid $orm_map_input_border;

    background-color: $orm_search_input_bg;
    color: $orm_search_input_color;

    border-radius: $border-radius-small;
    position: relative;

    .orm-reservationInfobar & {
      background-color: $orm_reservation_input_bg;
      color: $orm_reservation_input_color;
      border: 1px solid $orm_reservation_input_border;
    }

    small {
      font-size: rem-calc(10px);
      font-weight: 400;
      line-height: 1;
      // line-height: rem-calc(22px);
    }
  }
}

.orm-datepicker {
  display: flex;
  flex-wrap: wrap;

  &--disabled {
    * {
      cursor: default !important;
    }
  }
}

.orm-datecol {
  width: 100%;
  flex: 1 100%;
  @media only screen and (min-width: $sm-viewport) {
    flex: 1 50%;
  }
}

.datepicker {
  > * {
    display: inline-block;
  }
}

.datepicker__mapModule {
  position: relative;
  z-index: 500;
  &__dateTimeTitle {
    p {
      margin: 0;
      padding-left: rem-calc(12px);
      font-size: rem-calc(15px);
      font-weight: 600;
    }
    margin-bottom: rem-calc(4px);
  }
  &__dateTimeBorder {
    background-color: $orm_form_bg_color;
    border: 1px solid $orm_txt_dark_color;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    padding: rem-calc(4px) rem-calc(12px);
    margin-bottom: rem-calc(22px);
    max-width: rem-calc(226px);
    width: auto;
  }
  .datepicker__input {
    border: none;
    font-size: rem-calc(15px);
    font-weight: 600;
    cursor: pointer;
    width: auto;
    height: auto;
    display: inline-block;
    margin-right: 0;
    white-space: nowrap;
  }
  .dateTimeTitle {
    p {
      margin: 0;
      font-size: rem-calc(9px);
      font-weight: 400;
    }
  }
  .timepicker__input {
    select {
      border: none;
      font-size: rem-calc(15px);
      font-weight: 600;
      cursor: pointer;
      appearance: none;
      &:focus {
        outline: none;
      }
    }
  }
  .mapModule__datetimepicker__icon {
    margin-left: rem-calc(4px);
    font-size: rem-calc(14px);
  }
  .module__abholung,
  .module__abgabe {
    display: block;
  }
}

.datepicker__infobar {
  position: relative;
  z-index: 500;
  flex: 2;
  display: flex;
  &__dateTimeTitle {
    p {
      margin: 0;
      padding-left: rem-calc(12px);
      font-size: rem-calc(15px);
      font-weight: 600;
    }
    margin-bottom: rem-calc(4px);
  }
  &__dateTimeBorder {
    background-color: $orm_bg_dark_color;
    border: 1px solid $orm_txt_light_color;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    padding: rem-calc(4px) rem-calc(12px);
    margin-bottom: rem-calc(8px);
    max-width: rem-calc(226px);
    height: rem-calc(37px);
    width: auto;
    margin-right: rem-calc(10px);
  }
  .datepicker__input {
    border: none;
    font-size: rem-calc(15px);
    font-weight: 600;
    cursor: pointer;
    width: auto;
    height: auto;
    display: inline-block;
    margin-right: 0;
  }
  .dateTimeTitle {
    p {
      margin: 0;
      font-size: rem-calc(9px);
      font-weight: 400;
    }
  }
  .timepicker__input {
    select {
      background-color: $orm_bg_dark_color;
      color: $orm_txt_light_color;
      border: none;
      font-size: rem-calc(15px);
      font-weight: 600;
      cursor: pointer;
      appearance: none;
      &:focus {
        outline: none;
      }
    }
  }
  .infobar__datetimepicker__icon {
    margin-left: rem-calc(4px);
    font-size: rem-calc(14px);
  }
  .module__abholung,
  .module__abgabe {
    display: block;
    flex: 1;
  }
}

@media (min-width: 360px) and (max-width: 1023px) {
  .datepicker__mapModule .module__abholung,
  .datepicker__mapModule .module__abgabe {
    display: inline-block;
  }
  .datepicker__mapModule .module__abholung {
    margin-right: rem-calc(15px);
  }
  .datepicker__mapModule__dateTimeBorder {
    width: rem-calc(250px);
  }
}
