.orm-bookings-type-label {
  border-radius: 8px;
  background-color: #24282c17;
  display: inline-block;
  font-size: rem-calc(13px);
  font-weight: 600;
  padding-left: rem-calc(10px);
  padding-right: rem-calc(10px);
  text-transform: uppercase;
  margin-bottom: 16px;
}

.orm-bookings-type {
  margin-bottom: 24px;
  background-color: white;
  // width: 100%;
  font-size: rem-calc(14px);
  font-weight: 600;
  &--reservation {
    border-left: 4px solid #00b837;
  }
  &--contract_co {
    border-left: 4px solid #24282c;
  }
  &--ci_made {
    border-left: 4px solid #979797;
  }
}

.orm-bookinglist-title {
  margin: 0;
  font-size: rem-calc(10px);
  text-transform: uppercase;
  font-weight: 400;
}

.orm-bookinglist-title--pickupdelevery {
  padding-left: rem-calc(34px);
}

.orm-bookinglist-subline {
  margin: 0;
  font-size: rem-calc(12px);
  font-weight: 400;
}

.orm-border-left-bookings {
  border-left: 2px solid #d3d6d8;
  padding-left: 30px;
  height: 100%;
  @media screen and (max-width: $lg-viewport) {
    border-left: 0 transparent;
    padding-left: 0;
  }
}

.orm-license_plate {
  border-radius: 4px;
  padding-left: 16px;
  padding-right: 4px;
  padding-bottom: 1px;
  border: 1px solid #707070;
  font-size: 13px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: #0682e3;
    width: 10px;
    height: 100%;
  }
}

.orm-bookings__dwn_links {
  padding-right: 16px;
  cursor: pointer;
  font-size: rem-calc(14px);
}
