#crentSearchBar {
  .timepicker__input {
    display: inline-block;
  }
}
.orm-searchbar {
  &__title {
    color: $orm_txt_headline_searchbar;

    &--dark {
      color: $orm_txt_headline_searchbar_dark;
    }
  }
}
.orm-searchbar__tabs {
  max-width: rem-calc(990px);
}
.orm-searchbar__datepicker .orm-dateselect small {
  line-height: 1;
}
.orm-searchbar__datepicker {
  max-width: rem-calc(990px);
  color: $orm_searchbar_color;
}

.orm-searchbar__headline {
  padding-left: rem-calc(8px);
  margin-bottom: rem-calc(4px);
  font-size: rem-calc(15px);
  font-weight: 600;
  line-height: 1.33333333;
  strong {
    font-weight: 600;
  }
}

.orm-searchbar__padding {
  background-color: $orm_searchbar_bg;
  border-radius: $border-radius-big;
  padding: rem-calc(24px) rem-calc(8px);
  @media (min-width: $sm-viewport) {
    padding: rem-calc(24px) rem-calc(16px);
  }
}

.orm-searchbar__padding--map {
  background-color: $orm_map_stationlist_bg1;
}
.orm-searchbar__datepicker_ALL .orm-searchbar__padding {
  border-radius: 0 $border-radius-big $border-radius-big $border-radius-big;
  @media (max-width: $sm-viewport) {
    border-radius: $border-radius-big;
  }
}
