// CSS Reset Styling

*,
*:before,
*:after {
  box-sizing: border-box;
}
textarea,
input[type="range"],
input,
input:matches([type="password"], [type="search"]) {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

// Radio Buttons

.orm-radio {
  width: fit-content;
  font-size: inherit;
  font-family: inherit;
  color: $orm_txt_dark_color;
  cursor: pointer;
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 1em;
  &.orm-multisize {
    .orm-radio__label {
      font-size: 0.875em;
      b {
        font-weight: 600;
        font-size: 1.142857142857143em;
      }
    }
  }
  &.orm-withInput {
    .orm-radio__input {
      justify-content: center;
    }
    .orm-radio__control {
      transform: translateY(-0.1em);
    }
    .orm-input.orm-inline {
      margin-left: rem-calc(16px);
      input {
        max-width: 15ch;
      }
    }
  }
}

.orm-radio__input {
  display: flex;
  flex-direction: column;
  input {
    opacity: 0;
    width: 0;
    height: 0;
    margin: 0;
    + .orm-radio__control::before {
      content: "";
      width: 0.5em;
      height: 0.5em;
      box-shadow: inset 0.5em 0.5em currentColor;
      border-radius: 50%;
      transition: 90ms transform ease-in;
      transform: scale(0);
    }
    &:checked + .orm-radio__control::before {
      transform: scale(1);
    }
  }
}

.orm-radio__control {
  display: grid;
  place-items: center;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: 0.1em solid currentColor;
  transform: translateY(0.25em); // anpassen an Schriftart
}

.orm-radio__label {
  line-height: 1.5;
  b {
    font-weight: 600;
  }
}

// Checkboxes
.orm-checkbox {
  width: fit-content;
  display: grid;
  grid-template-columns: min-content auto auto;
  grid-gap: 1em;
  //font-size: inherit;
  font-family: inherit;
  color: $orm_txt_dark_color;
  cursor: pointer;

  &.orm-disabled {
    color: $orm_form_text_inactive_color;
    cursor: not-allowed;
    path {
      stroke: $orm_form_text_inactive_color;
    }
  }
  &.orm-multisize {
    .orm-checkbox__label {
      font-size: 0.875em;
      b {
        font-weight: 600;
        font-size: 1.142857142857143em;
      }
    }
  }
  &.orm-withSelect {
    .orm-checkbox__input {
      grid-template-areas: none;
    }
    .orm-checkbox__control {
      transform: translateY(0);
    }
  }
}

.orm-checkbox__input {
  display: grid;
  grid-template-areas: "checkbox";

  > * {
    grid-area: checkbox;
  }

  input {
    opacity: 0;
    width: 1em;
    height: 1em;
    margin: 0;
    &:checked + .orm-checkbox__control svg {
      transform: scale(1.5);
    }
    &:checkbox:disabled + .orm-checkbox__control {
      color: $orm_form_text_inactive_color;
    }
  }
}

.orm-checkbox__control {
  transform: translateY(0.25em); // anpassen an Schriftart
  display: inline-grid;
  width: 1em;
  height: 1em;
  border-radius: 0.25em;
  border: 0.1em solid currentColor;

  &--error {
    border: 0.1em solid $orm_form_error_color;
  }
  svg {
    transition: transform 0.1s ease-in 25ms;
    transform: scale(0);
    transform-origin: bottom left;
  }
  path {
    stroke: $orm_form_checked_color;
  }
}

.orm-checkbox__label {
  align-self: center;
  line-height: 1.5;
  b {
    font-weight: 600;
  }
  p {
    display: inline;
  }
}

.orm-fontbase14 {
  font-size: rem-calc(14px);
}

// Selects

.orm-select {
  &.orm-select__center {
    align-self: center;
  }
  .orm-select__control {
    display: grid;
    width: 100%;
    min-width: 15ch;
    //max-width: 36ch;
    border: 1px solid $orm_form_border_color;
    border-radius: 0.25em;
    padding: 0.5em 1em;
    font-size: rem-calc(16px);
    @media (min-width: $sm-viewport) {
      font-size: rem-calc(14px);
    }
    font-weight: 600;
    cursor: pointer;
    line-height: 1.4;
    background-color: $orm_form_bg_color;
    grid-template-areas: "select";
    align-items: center;
    transition: 120ms border-color ease-in;
    &.orm-inline {
      width: fit-content;
      min-width: initial;
      display: inline-grid;
      padding: rem-calc(8px);
      margin-right: rem-calc(-8px);
      @media (max-width: $sm-viewport) {
        font-size: rem-calc(15px);
        padding: rem-calc(4px);
      }
      @media (max-width: "380px") {
        font-size: rem-calc(14px);
        padding: rem-calc(4px);
      }
    }
    &:hover {
      border-color: $orm_form_focus_color;
    }

    select {
      appearance: none;
      background-color: transparent;
      border: none;
      padding: 0 1em 0 0;
      margin: 0;
      width: 100%;
      font-family: inherit;
      font-size: inherit;
      cursor: inherit;
      line-height: inherit;
      outline: none;
      grid-area: select;
    }
    select::-ms-expand {
      display: none;
    }
    .selectColor {
      color: inherit;
      &:invalid {
        color: $orm_form_placeholder_color;

        option {
          color: $orm_txt_dark_color;
        }
      }
    }
  }
  &:not(.orm-multiple) .orm-select__control::after {
    content: "";
    width: 0.8em;
    height: 0.5em;
    background-color: $orm_txt_dark_color;
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    grid-area: select;
    justify-self: end;
    pointer-events: none;
  }
  &.orm-multiple .orm-select__control {
    padding-top: 1em;
    padding-bottom: 1em;
    select {
      padding-right: 0;
      height: 6rem;
      &:not(:disabled) option {
        white-space: normal;
        border-radius: 0.25em;
        transition: 120ms all ease-in;
        margin-bottom: rem-calc(4px);

        &:checked {
          background: linear-gradient(rgb(211, 214, 216), rgb(211, 214, 216));

          color: black !important;
        }
      }
    }
  }
  &.orm-disabled {
    .orm-select__control {
      background-color: $orm_form_bg_inactive_color;
      cursor: no-drop;
      &:focus,
      &:hover {
        border-color: $orm_form_border_color;
      }
      &::after {
        display: none;
      }
      select {
        color: #000;
      }
    }
  }
}

// input & textarea
.orm-input {
  &.orm-inline {
    display: inline-block;
    margin-left: rem-calc(8px);
  }
  &__control {
    width: 100%;
    min-width: 15ch;
    //max-width: 36ch;
    border: 1px solid $orm_form_border_color;
    border-radius: 0.25em;
    padding: 0.5em 1em;
    font-family: inherit;
    font-size: 1em;
    @media (min-width: $sm-viewport) {
      font-size: 0.875em;
    }
    font-weight: 400;
    line-height: 1.4;
    background-color: $orm_form_bg_color;
    transition: 120ms border-color ease-in;
    outline: none;

    &:focus,
    &:hover {
      outline: none;
      border-color: $orm_form_focus_color;
    }
    &::placeholder {
      font-family: inherit;
      font-size: 0.875em;
      font-weight: 400;
      line-height: 1.4;
      opacity: 1;
      color: $orm_form_placeholder_color;
    }
    &[readonly],
    &[disabled] {
      background-color: $orm_form_bg_inactive_color;
      cursor: no-drop;
      &:focus,
      &:hover {
        border-color: $orm_form_border_color;
      }
    }
  }
  & textarea {
    resize: vertical;
    &[readonly],
    &[disabled] {
      resize: none;
    }
  }
  &.orm-withIcon {
    //position: relative;
    &.orm-withIcon__wide {
      .orm-input__control {
        min-width: 12ch;
        max-width: unset;
      }
    }
    .orm-input__control {
      width: auto;
      min-width: 5ch;
      max-width: 12ch;
    }
    i {
      position: relative;
      top: 32%;
      right: rem-calc(24px);
      font-size: 1em;
      @media (min-width: $sm-viewport) {
        font-size: 0.875em;
      }
      cursor: pointer;
      &:hover {
        color: $orm_green;
      }
    }
  }
}

// ORM form
.orm-form {
  &__container {
    @media only screen and (min-width: $md-viewport) {
      padding-right: rem-calc(48px);
    }
    @media only screen and (min-width: $lg-viewport) {
      padding-right: rem-calc(136px);
    }
    &__nop {
      padding-right: rem-calc(16px);
    }
    .orm-radio {
      margin-top: rem-calc(8px);
      margin-bottom: rem-calc(16px);
    }
    .orm-checkbox {
      margin-top: rem-calc(8px);
      margin-bottom: rem-calc(16px);

      .orm-checkbox__label {
        //max-width: 26ch;
      }
      &.orm-fontbase14 {
        .orm-checkbox__label {
          //max-width: 36ch;
        }
      }
    }
    .orm-select {
      margin-top: rem-calc(8px);
      margin-bottom: rem-calc(16px);
    }
    .orm-input:not(.orm-inline) {
      margin-top: rem-calc(8px);
      margin-bottom: rem-calc(16px);
    }
  }
  &__label {
    display: block;
    line-height: 1.2;
    padding-bottom: rem-calc(3px);
    font-family: inherit;
    font-size: rem-calc(12px);
    font-weight: 600;
    color: $orm_txt_dark_color;
  }
  &__button__container {
    margin-top: rem-calc(24px);
    margin-bottom: rem-calc(72px);
    @media (min-width: $md-viewport) {
      margin-top: rem-calc(168px);
    }
    text-align: right;
  }
}
//
//
//
//
//
//
//

// forms WIP
.orm-form__element {
  margin-bottom: rem-calc(25px);
  max-width: rem-calc(326px);
  input {
    width: 100%;
    height: rem-calc(36px);
    border: 1px solid $orm_form_border_color;
    padding-left: rem-calc(12px);
    padding-right: rem-calc(12px);
    font-family: inherit;
    font-size: rem-calc(16px);
    @media (min-width: $sm-viewport) {
      font-size: rem-calc(14px);
    }
    color: $orm_txt_dark_color;
  }
}
.orm-form__block {
  margin-bottom: rem-calc(40);
}
.orm-select .orm-select__control.orm-form__error,
.orm-form__error {
  border-color: $orm_form_error_color;
}

.orm-input__showPwd {
  position: absolute;
  height: 36px;
  width: 36px;
  text-align: center;
  top: rem-calc(17px);
  right: rem-calc(0);
  cursor: pointer;

  &_eye {
    padding-top: rem-calc(10px);
  }
}
