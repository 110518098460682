// basic http://flexboxgrid.com/

$gutter-width: 16px;
$outer-margin: 2em;
$gutter-compensation: -8px; //calc((1rem * 0.5) * -1);
$half-gutter-width: 8px; //calc((1rem * 0.5));

// $grid-breakpoints: xs, sm, md, lg;

$xs-min: 30em;
$sm-min: 576px;
$md-min: 768px;
$lg-min: 1080px; //75rem;
$lgx-min: 1320px; //75rem;

$container-sm: $md-min;
$container-md: $lg-min;
$container-lg: $lgx-min;

$xs-viewport: $xs-min;
$sm-viewport: $sm-min;
$md-viewport: $md-min;
$lg-viewport: $lg-min; //75em

.orm-container-fluid,
.orm-container {
  margin-right: auto;
  margin-left: auto;
}

.orm-container {
  width: 100%;
  padding-right: $gutter-width;
  padding-left: $gutter-width;
  margin-right: auto;
  margin-left: auto;
}

.orm-flex{
  @media only screen and (min-width: $sm-viewport) {
    display: flex;
  }
}
// .orm-container-fluid {
//   padding-right: $outer-margin;
//   padding-left: $outer-margin;
// }

.orm-row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: $gutter-compensation;
  margin-left: $gutter-compensation;
}
@media only screen and (max-width: $md-viewport) {
  .orm-container > .orm-row {
    margin-right: 0;
    margin-left: 0;
  }
}

.orm-row.reverse {
  flex-direction: row-reverse;
}

.orm-col.reverse {
  flex-direction: column-reverse;
}

.orm-col-xs,
.orm-col-xs-1,
.orm-col-xs-2,
.orm-col-xs-3,
.orm-col-xs-4,
.orm-col-xs-5,
.orm-col-xs-6,
.orm-col-xs-7,
.orm-col-xs-8,
.orm-col-xs-9,
.orm-col-xs-10,
.orm-col-xs-11,
.orm-col-xs-12,
.orm-col-xs-offset-0,
.orm-col-xs-offset-1,
.orm-col-xs-offset-2,
.orm-col-xs-offset-3,
.orm-col-xs-offset-4,
.orm-col-xs-offset-5,
.orm-col-xs-offset-6,
.orm-col-xs-offset-7,
.orm-col-xs-offset-8,
.orm-col-xs-offset-9,
.orm-col-xs-offset-10,
.orm-col-xs-offset-11,
.orm-col-xs-offset-12 {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: $half-gutter-width;
  padding-left: $half-gutter-width;
}

.orm-col-xs {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.orm-col-xs-1 {
  flex-basis: 8.33333333%;
  max-width: 8.33333333%;
}

.orm-col-xs-2 {
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
}

.orm-col-xs-3 {
  flex-basis: 25%;
  max-width: 25%;
}

.orm-col-xs-4 {
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
}

.orm-col-xs-5 {
  flex-basis: 41.66666667%;
  max-width: 41.66666667%;
}

.orm-col-xs-6 {
  flex-basis: 50%;
  max-width: 50%;
}

.orm-col-xs-7 {
  flex-basis: 58.33333333%;
  max-width: 58.33333333%;
}

.orm-col-xs-8 {
  flex-basis: 66.66666667%;
  max-width: 66.66666667%;
}

.orm-col-xs-9 {
  flex-basis: 75%;
  max-width: 75%;
}

.orm-col-xs-10 {
  flex-basis: 83.33333333%;
  max-width: 83.33333333%;
}

.orm-col-xs-11 {
  flex-basis: 91.66666667%;
  max-width: 91.66666667%;
}

.orm-col-xs-12 {
  flex-basis: 100%;
  max-width: 100%;
}

.orm-col-xs-offset-0 {
  margin-left: 0;
}

.orm-col-xs-offset-1 {
  margin-left: 8.33333333%;
}

.orm-col-xs-offset-2 {
  margin-left: 16.66666667%;
}

.orm-col-xs-offset-3 {
  margin-left: 25%;
}

.orm-col-xs-offset-4 {
  margin-left: 33.33333333%;
}

.orm-col-xs-offset-5 {
  margin-left: 41.66666667%;
}

.orm-col-xs-offset-6 {
  margin-left: 50%;
}

.orm-col-xs-offset-7 {
  margin-left: 58.33333333%;
}

.orm-col-xs-offset-8 {
  margin-left: 66.66666667%;
}

.orm-col-xs-offset-9 {
  margin-left: 75%;
}

.orm-col-xs-offset-10 {
  margin-left: 83.33333333%;
}

.orm-col-xs-offset-11 {
  margin-left: 91.66666667%;
}

.start-xs {
  justify-content: flex-start !important;
}

.center-xs {
  justify-content: center !important;
}

.end-xs {
  justify-content: flex-end !important;
}

.top-xs {
  align-items: flex-start;
}

.middle-xs {
  align-items: center;
}
.middle-self-xs {
  align-self: center;
}

.bottom-xs {
  align-items: flex-end;
}

.bottom-self-xs {
  align-self: flex-end;
}

.around-xs {
  justify-content: space-around;
}

.between-xs {
  justify-content: space-between;
}

.first-xs {
  order: -1;
}

.last-xs {
  order: 1;
}

@media only screen and (min-width: $sm-viewport) {
  .orm-container {
    max-width: $container-sm;
  }

  .orm-col-sm,
  .orm-col-sm-1,
  .orm-col-sm-2,
  .orm-col-sm-3,
  .orm-col-sm-4,
  .orm-col-sm-5,
  .orm-col-sm-6,
  .orm-col-sm-7,
  .orm-col-sm-8,
  .orm-col-sm-9,
  .orm-col-sm-10,
  .orm-col-sm-11,
  .orm-col-sm-12,
  .orm-col-sm-offset-0,
  .orm-col-sm-offset-1,
  .orm-col-sm-offset-2,
  .orm-col-sm-offset-3,
  .orm-col-sm-offset-4,
  .orm-col-sm-offset-5,
  .orm-col-sm-offset-6,
  .orm-col-sm-offset-7,
  .orm-col-sm-offset-8,
  .orm-col-sm-offset-9,
  .orm-col-sm-offset-10,
  .orm-col-sm-offset-11,
  .orm-col-sm-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: $half-gutter-width;
    padding-left: $half-gutter-width;
  }

  .orm-col-sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .orm-col-sm-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .orm-col-sm-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .orm-col-sm-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .orm-col-sm-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .orm-col-sm-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .orm-col-sm-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .orm-col-sm-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .orm-col-sm-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .orm-col-sm-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .orm-col-sm-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .orm-col-sm-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .orm-col-sm-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .orm-col-sm-offset-0 {
    margin-left: 0;
  }

  .orm-col-sm-offset-1 {
    margin-left: 8.33333333%;
  }

  .orm-col-sm-offset-2 {
    margin-left: 16.66666667%;
  }

  .orm-col-sm-offset-3 {
    margin-left: 25%;
  }

  .orm-col-sm-offset-4 {
    margin-left: 33.33333333%;
  }

  .orm-col-sm-offset-5 {
    margin-left: 41.66666667%;
  }

  .orm-col-sm-offset-6 {
    margin-left: 50%;
  }

  .orm-col-sm-offset-7 {
    margin-left: 58.33333333%;
  }

  .orm-col-sm-offset-8 {
    margin-left: 66.66666667%;
  }

  .orm-col-sm-offset-9 {
    margin-left: 75%;
  }

  .orm-col-sm-offset-10 {
    margin-left: 83.33333333%;
  }

  .orm-col-sm-offset-11 {
    margin-left: 91.66666667%;
  }

  .start-sm {
    justify-content: flex-start !important;
  }

  .center-sm {
    justify-content: center !important;
  }

  .end-sm {
    justify-content: flex-end !important;
  }

  .top-sm {
    align-items: flex-start;
  }

  .middle-sm {
    align-items: center;
  }

  .middle-self-sm {
    align-self: center;
  }

  .bottom-sm {
    align-items: flex-end;
  }

  .bottom-self-sm {
    align-self: flex-end;
  }

  .around-sm {
    justify-content: space-around;
  }

  .between-sm {
    justify-content: space-between;
  }

  .first-sm {
    order: -1;
  }

  .last-sm {
    order: 1;
  }
}

@media only screen and (min-width: $md-viewport) {
  .orm-container {
    max-width: $container-md;
  }

  .orm-col-md,
  .orm-col-md-1,
  .orm-col-md-2,
  .orm-col-md-3,
  .orm-col-md-4,
  .orm-col-md-5,
  .orm-col-md-6,
  .orm-col-md-7,
  .orm-col-md-8,
  .orm-col-md-9,
  .orm-col-md-10,
  .orm-col-md-11,
  .orm-col-md-12,
  .orm-col-md-offset-0,
  .orm-col-md-offset-1,
  .orm-col-md-offset-2,
  .orm-col-md-offset-3,
  .orm-col-md-offset-4,
  .orm-col-md-offset-5,
  .orm-col-md-offset-6,
  .orm-col-md-offset-7,
  .orm-col-md-offset-8,
  .orm-col-md-offset-9,
  .orm-col-md-offset-10,
  .orm-col-md-offset-11,
  .orm-col-md-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: $half-gutter-width;
    padding-left: $half-gutter-width;
  }

  .orm-col-md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .orm-col-md-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .orm-col-md-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .orm-col-md-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .orm-col-md-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .orm-col-md-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .orm-col-md-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .orm-col-md-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .orm-col-md-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .orm-col-md-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .orm-col-md-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .orm-col-md-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .orm-col-md-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .orm-col-md-offset-0 {
    margin-left: 0;
  }

  .orm-col-md-offset-1 {
    margin-left: 8.33333333%;
  }

  .orm-col-md-offset-2 {
    margin-left: 16.66666667%;
  }

  .orm-col-md-offset-3 {
    margin-left: 25%;
  }

  .orm-col-md-offset-4 {
    margin-left: 33.33333333%;
  }

  .orm-col-md-offset-5 {
    margin-left: 41.66666667%;
  }

  .orm-col-md-offset-6 {
    margin-left: 50%;
  }

  .orm-col-md-offset-7 {
    margin-left: 58.33333333%;
  }

  .orm-col-md-offset-8 {
    margin-left: 66.66666667%;
  }

  .orm-col-md-offset-9 {
    margin-left: 75%;
  }

  .orm-col-md-offset-10 {
    margin-left: 83.33333333%;
  }

  .orm-col-md-offset-11 {
    margin-left: 91.66666667%;
  }

  .start-md {
    justify-content: flex-start !important;
  }

  .center-md {
    justify-content: center !important;
  }

  .end-md {
    justify-content: flex-end !important;
  }

  .top-md {
    align-items: flex-start;
  }

  .middle-md {
    align-items: center;
  }

  .middle-self-md {
    align-self: center;
  }

  .bottom-md {
    align-items: flex-end;
  }

  .bottom-self-md {
    align-self: flex-end;
  }

  .around-md {
    justify-content: space-around;
  }

  .between-md {
    justify-content: space-between;
  }

  .first-md {
    order: -1;
  }

  .last-md {
    order: 1;
  }
}

@media only screen and (min-width: $lg-viewport) {
  .orm-container {
    max-width: $container-lg;
  }

  .orm-col-lg,
  .orm-col-lg-1,
  .orm-col-lg-2,
  .orm-col-lg-3,
  .orm-col-lg-4,
  .orm-col-lg-5,
  .orm-col-lg-6,
  .orm-col-lg-7,
  .orm-col-lg-8,
  .orm-col-lg-9,
  .orm-col-lg-10,
  .orm-col-lg-11,
  .orm-col-lg-12,
  .orm-col-lg-offset-0,
  .orm-col-lg-offset-1,
  .orm-col-lg-offset-2,
  .orm-col-lg-offset-3,
  .orm-col-lg-offset-4,
  .orm-col-lg-offset-5,
  .orm-col-lg-offset-6,
  .orm-col-lg-offset-7,
  .orm-col-lg-offset-8,
  .orm-col-lg-offset-9,
  .orm-col-lg-offset-10,
  .orm-col-lg-offset-11,
  .orm-col-lg-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: $half-gutter-width;
    padding-left: $half-gutter-width;
  }

  .orm-col-lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .orm-col-lg-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .orm-col-lg-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .orm-col-lg-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .orm-col-lg-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .orm-col-lg-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .orm-col-lg-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .orm-col-lg-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .orm-col-lg-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .orm-col-lg-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .orm-col-lg-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .orm-col-lg-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .orm-col-lg-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .orm-col-lg-offset-0 {
    margin-left: 0;
  }

  .orm-col-lg-offset-1 {
    margin-left: 8.33333333%;
  }

  .orm-col-lg-offset-2 {
    margin-left: 16.66666667%;
  }

  .orm-col-lg-offset-3 {
    margin-left: 25%;
  }

  .orm-col-lg-offset-4 {
    margin-left: 33.33333333%;
  }

  .orm-col-lg-offset-5 {
    margin-left: 41.66666667%;
  }

  .orm-col-lg-offset-6 {
    margin-left: 50%;
  }

  .orm-col-lg-offset-7 {
    margin-left: 58.33333333%;
  }

  .orm-col-lg-offset-8 {
    margin-left: 66.66666667%;
  }

  .orm-col-lg-offset-9 {
    margin-left: 75%;
  }

  .orm-col-lg-offset-10 {
    margin-left: 83.33333333%;
  }

  .orm-col-lg-offset-11 {
    margin-left: 91.66666667%;
  }

  .start-lg {
    justify-content: flex-start !important;
  }

  .center-lg {
    justify-content: center !important;
  }

  .end-lg {
    justify-content: flex-end !important;
  }

  .top-lg {
    align-items: flex-start;
  }

  .middle-lg {
    align-items: center;
  }

  .middle-self-lg {
    align-self: center;
  }

  .bottom-lg {
    align-items: flex-end;
  }

  .bottom-self-lg {
    align-self: flex-end;
  }

  .around-lg {
    justify-content: space-around;
  }

  .between-lg {
    justify-content: space-between;
  }

  .first-lg {
    order: -1;
  }

  .last-lg {
    order: 1;
  }
}
